import React from 'react'
import {
  Route,
  Switch,
} from 'react-router-dom'
import KycPage from './Kyc'
import { useKyc } from './Kyc/context/KycContext'
import Conclusion from './Kyc/pages/Conclusion'
import LoggedArea from './LoggedArea'
import Onboarding from './Onboarding/Onboarding'

const PagesGuard = () => {
  const { skipOnboarding, status } = useKyc()

  const kycIsApproved = status === 'approved'

  if (!kycIsApproved || !skipOnboarding) {
    return (
      <Switch>
        {kycIsApproved && (
          <Route
            component={Onboarding}
            exact
            path="/onboarding"
          />
        )}
        <Route
          component={KycPage}
          path="*"
        />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route
        component={Conclusion}
        exact
        path="/kyc-conclusion"
      />
      <Route
        component={LoggedArea}
        path="*"
      />
    </Switch>
  )
}

export default PagesGuard
