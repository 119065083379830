import React, { Component } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'

import {
  Route,
  withRouter,
} from 'react-router-dom'

import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import {
  compose,
  pipe,
  startsWith,
  tail,
} from 'ramda'

import { requestLogin as requestLoginAction } from './Account/actions/actions'
import { inactiveCompanyLogin } from '../vendor/googleTagManager'

import Account from './Account'
import Loader from '../components/Loader'

import environment, { apiAdminUrl, impersonate } from '../environment'
import { page as appcuesPage } from '../vendor/appcues'
import { KycProvider } from './Kyc/context/KycContext'
import PagesGuard from './pagesGuard'
import { shouldSkipOnboarding } from './Welcome/reducer'

const mapStateToProps = ({
  account: {
    client,
    company,
    companyId,
    impersonationEnv,
    impersonationKey,
    sessionId,
    user,
  },
  welcome,
}) => ({
  client,
  company,
  companyId,
  impersonationEnv,
  impersonationKey,
  loadingOnboardingAnswers: welcome.loading,
  sessionId,
  skipOnboarding: shouldSkipOnboarding({ company, environment, welcome }),
  user,
})

const mapDispatchToProps = {
  requestLogin: requestLoginAction,
}
const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)
const parseQueryString = pipe(tail, qs.parse)

const getJwt = () => {
  const jwtTwoDotZero = Cookies.get('mp_tk')
  if (!jwtTwoDotZero) {
    localStorage.removeItem('redux_localstorage_simple_account.companyId')
    localStorage.removeItem('redux_localstorage_simple_account.jwt')
  }
  return jwtTwoDotZero
}

const getAzureJwt = () => {
  const jwtAzure = localStorage.getItem('accessToken')

  return jwtAzure
}

const getSessionId = (props, queryString) => (
  queryString.session_id || props.sessionId
)

const getCompanyId = (props, queryString) => {
  const impersonation = queryString.impersonation_key || props.impersonationKey
  const companyId = queryString.companyid || props.company_id
  return impersonation || companyId
}

const isLogged = (sessionId, companyId) => (
  getAzureJwt() || sessionId || (companyId && getJwt())
)

class Root extends Component {
  componentDidMount () {
    const {
      client,
      companyId: companyIdState,
      history: { listen },
      impersonationEnv,
      location: { search: queryString },
      requestLogin,
    } = this.props

    const historyUnlisten = listen(() => {
      appcuesPage()
    })

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ historyUnlisten })

    const parsedQueryString = parseQueryString(queryString)
    const sessionId = getSessionId(this.props, parsedQueryString)
    const companyId = getCompanyId(this.props, parsedQueryString)
      || companyIdState
    const jwtAuth = getJwt()
    const jwtAzureAuth = getAzureJwt()

    if (parsedQueryString.session_id) {
      localStorage.setItem('dashboardChoice', 'new-dash')
    }

    if (!client && !sessionId && !jwtAzureAuth) {
      inactiveCompanyLogin()
    }

    if (!client && (sessionId || (jwtAuth && companyId) || jwtAzureAuth)) {
      const payload = {
        environment,
      }

      if (sessionId) {
        payload.session_id = sessionId
      } else if (jwtAzureAuth) {
        payload.impersonationKey = companyId
        payload.impersonationEnv = impersonationEnv || environment
        payload.environment = impersonationEnv || environment
        payload.options = {
          baseURL: apiAdminUrl,
        }
        payload.company_id = companyId
        payload.jwt = jwtAzureAuth
      } else if (jwtAuth && companyId) {
        payload.company_id = companyId
        payload.jwt = jwtAuth
      }

      requestLogin(payload)
    }
  }

  componentWillUnmount () {
    const { historyUnlisten } = this.state
    historyUnlisten()
  }

  render () {
    const {
      client,
      company,
      companyId,
      history,
      impersonationKey,
      loadingOnboardingAnswers,
      location: {
        pathname: path,
        search: queryString,
      },
      sessionId,
      skipOnboarding,
      user,
    } = this.props

    const parsedQueryString = parseQueryString(queryString)
    const redirect = parsedQueryString.redirect || '/'

    if (isLogged(sessionId, companyId) && !impersonationKey && impersonate) {
      if (!startsWith('/account', path)) {
        history.replace('/account/impersonate')
        return null
      }
      return <Route path="/account/impersonate" component={Account} />
    }

    if (!client && isLogged(sessionId, companyId)) {
      return (
        <Loader
          opaqueBackground
          position="absolute"
          visible
        />
      )
    }

    if (!client && impersonate) {
      if (!startsWith('/account', path)) {
        history.replace('/account/impersonate')
        return null
      }
      return <Route path="/account/impersonate" component={Account} />
    }

    if (!client && !startsWith('/account', path)) {
      history.replace('/account/login')
      return null
    }

    if (!client) {
      return (
        <Route path="/account" component={Account} />
      )
    }

    if (user && startsWith('/account/login', path)) {
      history.replace(redirect)
      return null
    }

    if (!user || !company || loadingOnboardingAnswers) {
      return (
        <Loader
          opaqueBackground
          position="absolute"
          visible
        />
      )
    }

    return (
      <KycProvider skipOnboarding={skipOnboarding}>
        <PagesGuard />
      </KycProvider>
    )
  }
}

Root.propTypes = {
  client: PropTypes.object, // eslint-disable-line
  company: PropTypes.object, // eslint-disable-line
  companyId: PropTypes.string,
  history: PropTypes.shape({
    listen: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  impersonationEnv: PropTypes.string,
  impersonationKey: PropTypes.string,
  loadingOnboardingAnswers: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  requestLogin: PropTypes.func.isRequired,
  sessionId: PropTypes.string,
  skipOnboarding: PropTypes.bool,
  user: PropTypes.object, // eslint-disable-line
}

Root.defaultProps = {
  client: null,
  company: null,
  companyId: null,
  impersonationEnv: null,
  impersonationKey: null,
  sessionId: null,
  skipOnboarding: false,
  user: null,
}

export default enhance(Root)
