
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import React, { useState } from 'react'
import { setupClient } from '@pagarme/http-client'
import { setupRouters } from '@pagarme/dash-router'
import env from '../environment'
import Loader from '../components/Loader'

const PlatformProvider = ({ children }) => {
  const {
    accountId,
    company,
    environment,
    impersonationEnv,
    impersonationKey,
    jwt,
    merchantId,
    sessionId,
  } = useSelector(state => state.account)

  const history = useHistory()

  const [setupedClient, setSetupedClient] = useState(false)
  const [setupedRouter, setSetupedRouter] = useState(false)

  if (!setupedClient) {
    if (merchantId) {
      setupClient({
        authentication: {
          jwt,
          session_id: sessionId,
        },
        // eslint-disable-next-line sort-keys
        accountId,
        merchantId,
        // eslint-disable-next-line sort-keys
        environment: environment || impersonationEnv || env,
        // isImpersonate: !!impersonationKey,
      })
    } else {
      setupClient({
        authentication: {
          jwt,
          session_id: sessionId,
        },
        companyId: company.id,
        environment: environment || impersonationEnv || env,
        isImpersonate: !!impersonationKey,
      })
    }

    setSetupedClient(true)
  }

  if (!setupedRouter) {
    setupRouters({
      baseName: '#',
      history,
      useRouterAndBrowserRouter: true,
    })

    setSetupedRouter(true)
  }

  if (!setupedClient && !setupRouters) {
    return (
      <div data-testid="loader">
        <Loader
          position="absolute"
          visible
        />
      </div>
    )
  }

  return children
}

PlatformProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PlatformProvider
